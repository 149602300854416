/* Font */

.odd-row {
  background: #ddebf7;
}

.even-row {
  background: #fff;
}

@font-face {
  font-family: "fredoka_bold";
  src: url("../src/assets/fonts/Fredoka-Bold.ttf");
}

@font-face {
  font-family: "fredoka_regular";
  src: url("../src/assets/fonts/Fredoka-Regular.ttf");
}

/* for table */

.ant-menu-dark.ant-menu-inline .ant-menu-sub.ant-menu-inline {
  background: #32256a;
}

.ant-menu-dark .ant-menu-item-group-title {
  color: white;
  margin-top: 40px;
  margin-left: 10px;
}

/* for calendar */
.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}
