.Footer {
  background-image: url("../../assets/bg/footer-top-bg-2.png");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 350px;
  width: 100%;
  font-family: fredoka_regular;
}

.FirstColumn {
  display: flex;
  flex-direction: column;
}

.IconStyle {
  width: 24px;
  margin-right: 10px;
  padding: 10px 0;
}

.IconText {
  font-weight: 500;
}

.FooterTitleLayer {
  padding-top: 3%;
  position: relative;
}

.ContactTitle {
  color: #f44336;
  letter-spacing: 0.5px;
  font-family: fredoka_bold;
  margin-bottom: 20px;
  font-size: 28px;
}

.OpenTitle {
  color: #8373ce;
  letter-spacing: 0.5px;
  font-family: fredoka_bold;
  margin-bottom: 20px;
  font-size: 28px;
}

.FooterText {
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.FollowTitle {
  font-family: fredoka_bold;
  font-size: 16px;
}

.SocialIcon {
  width: 22px;
  margin-left: 20px;
}

.FooterTitleLayer::after {
  content: "";
  position: absolute;
  right: 70%;
  z-index: 2;
  width: 200px;
  height: 100px;
  background-image: url("../../assets/bg/footer-kid.png");
  background-repeat: no-repeat;
  background-size: 90%;
  bottom: -45%;
  transition: all 1s ease 0s;
}

.FooterTitleLayer:hover::after {
  right: 90%;
}

.FooterMiddleLayer {
  width: 100%;
  background-color: #b6cf4d;
  height: 80px;
  position: relative;
  margin-top: 100px;
}

.FooterMiddleLayer::before {
  content: "";
  width: 100%;
  height: 100px;
  position: absolute;
  background: url("../../assets/bg/footer-three-bg.png");
  background-repeat: no-repeat;
  bottom: 55%;
  background-size: auto;
  background-position: center;
}

.CopyText {
  color: white;
  font-size: 14px;
  font-weight: bold;
}

@media screen and (min-width: 576px) and (max-width: 1200px) {
  .FooterTitleLayer::after {
    display: none;
  }
}

@media screen and (min-width: 576px) and (max-width: 768px) {
  .FooterTitleLayer::after {
    display: none;
  }

  .ContactTitle {
    font-size: 20px;
  }

  .OpenTitle {
    font-size: 20px;
  }

  .IconStyle {
    width: 16px;
    padding: 10px 0;
  }

  .IconText {
    font-weight: 400;
    font-size: 12px;
  }
}

@media screen and (max-width: 576px) {
  .FooterTitleLayer::after {
    display: none;
  }
  .ContactTitle {
    font-size: 18px;
  }

  .OpenTitle {
    font-size: 18px;
  }

  .IconStyle {
    width: 16px;
  }

  .IconText {
    font-size: 12px;
  }

  .FooterMiddleLayer::before {
    background-position: 32%;
  }

  .CopyText {
    font-size: 12px;
  }
}
