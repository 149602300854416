.TopLayer {
  background: #03a9f4;
  width: 100%;
  height: 40px;
}

.SecondLayer {
  z-index: -99;
}

.LeftSection {
  display: flex;
  align-items: center;
  line-height: 40px;
}

.LeftPanelSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

.AddressIcon {
  color: white;
  font-size: 14px;
}

.EmailIcon {
  color: white;
  font-size: 14px;
  margin-left: 15px;
}

.AddressText {
  color: white;
  font-size: 0.9em;
  font-weight: 600;
  padding-left: 5px;
}

.VerticalLine {
  margin-left: 15px;
  text-align: center;
  height: 30px;
  border-right: 2px solid #19b4fa;
}

/* .MailSection {
  margin-left: 15px;
  line-height: 40px;
} */

.SocialIcon {
  font-size: 24px;
  color: white;
  margin: 0 10px;
}

.RightSection {
  display: flex;
  align-items: center;
  line-height: 45px;
  float: right;
}

.LinePic {
  width: 100%;
  height: 2px;
  position: absolute;
}

.Logo {
  height: 100%;
  width: 270px;
}

.IconFrame {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 75px;
}

.IconImg {
  width: 24px;
}

.IconText {
  font-size: 12px;
  font-weight: 600;
  margin-top: 5px;
}

.BottomGrass {
  background: url("../../assets/bg/menu-bg.png");
  content: "";
  width: 100%;
  height: 30px;
  /* position: absolute;
  border: 0px;
  left: 0; */
  background-repeat: repeat-x;
}

.NavLinkH::after {
  content: "";
  position: absolute;
  height: 56px;
  width: 46px;
  margin-left: 20px;
  visibility: hidden;
  top: 100px;
  background-image: url("../../assets/hover.png");
  background-repeat: no-repeat;
  transition: all 0.5s ease 0s;
  z-index: -99;
}

.Toogle {
  border: none;
  font-size: large;
}

.NavLinkH:hover::after {
  visibility: visible;
  top: 70px;
  /* z-index: -99; */
}

.MoblieMenu {
  display: none;
}

@media screen and (min-width: 768px) and (max-width: 992px) {
  .Logo {
    height: 100%;
    width: 180px;
  }

  .IconFrame {
    width: 54px;
  }

  .IconImg {
    width: 22px;
  }

  .IconText {
    font-size: 0.6em;
    font-weight: 600;
    margin-top: 5px;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .LeftPanelSection {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .AddressText {
    color: white;
    font-size: 0.7em;
    font-weight: 600;
    padding-left: 5px;
  }
  .AddressIcon {
    color: white;
    font-size: 14px;
  }

  .EmailIcon {
    color: white;
    font-size: 14px;
    margin-left: 10px;
  }

  .VerticalLine {
    margin-left: 5px;
    text-align: center;
    height: 20px;
    border-right: 2px solid #19b4fa;
  }

  .SocialIcon {
    font-size: 20px;
    color: white;
    margin: 0 10px;
  }

  /* setting for small device */

  .NavLinkH {
    display: none;
  }

  .MoblieMenu {
    display: contents;
  }
  .IconFrame {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    padding: 10px 0;
    border-bottom: 0.5px solid rgb(210, 210, 210);
  }

  .IconImg {
    width: 26px;
    margin-left: 5%;
  }

  .IconText {
    font-size: 0.8em;
    padding-left: 5%;
  }
}

@media screen and (max-width: 576px) {
  .Logo {
    height: 100%;
    width: 220px;
  }

  .TopLayer {
    background: #03a9f4;
    width: 100%;
    height: 80px;
  }
  .LeftSection {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .LeftPanelSection {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .AddressText {
    color: white;
    font-size: 0.9em;
    margin-left: 5px;
  }
  .AddressIcon {
    color: white;
    font-size: 16px;
  }

  .EmailIcon {
    color: white;
    font-size: 16px;
  }
  .VerticalLine {
    display: none;
  }

  .RightSection {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: #afe7f9;
    width: 70%;
  }

  .SocialIcon {
    font-size: 20px;
    color: white;
  }

  /* setting for small device */

  .NavLinkH {
    display: none;
  }

  .MoblieMenu {
    display: contents;
  }
  .IconFrame {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    padding: 10px 0;
    border-bottom: 0.5px solid rgb(210, 210, 210);
  }

  .IconImg {
    width: 26px;
    margin-left: 5%;
  }

  .IconText {
    font-size: 0.8em;
    padding-left: 5%;
  }
}
