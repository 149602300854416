.Banner {
  position: relative;
}

.SlideLayout {
  position: relative;
}

.SlideOneText {
  position: absolute;
  color: white;
  font-family: fredoka_bold;
  font-size: 60px;
  font-weight: 800;
  line-height: 70px;
  right: 50%;
  top: 15%;
  width: 35%;
  text-align: left;
  padding: 10px;
  border-radius: 15px;
  background: linear-gradient(
    270deg,
    rgba(131, 114, 207, 0) 15.65%,
    rgba(131, 114, 207, 0.696) 72.15%,
    rgba(131, 114, 207, 0.8) 99.33%
  );
}

.SlideStyle {
  width: 100%;
  height: 600px;
  filter: alpha(opacity=80);
}

.CarouselStyle {
  height: 700px;
}

.SlideTwoText {
  position: absolute;
  font-family: fredoka_bold;
  right: 40%;
  top: 10%;
  width: 35%;
  text-align: left;
  border-radius: 15px;
  padding: 10px;
  background: linear-gradient(
    270deg,
    rgba(255, 169, 244, 0) 15.65%,
    rgba(3, 169, 244, 0.696) 72.15%,
    rgba(3, 169, 244, 0.8) 99.33%
  );
}

.SlideTwoTextTitle {
  font-size: 60px;
  font-weight: 800;
  line-height: 70px;
  color: #f44336;
}

.SlideTwoTextContent {
  color: white;
  font-size: 30px;
}

.BannerBillboard {
  position: absolute;
  width: 100%;
  bottom: -30%;
}

.BannerBillboard h4 {
  font-family: fredoka_bold;
  color: white;
  letter-spacing: 1px;
}

.BannerBillboard p {
  color: white;
  font-size: 0.9em;
  font-weight: 600;
  padding: 0 3%;
}

.BannerBillboardPanel1 {
  height: 280px;
  border-radius: 20px;
  background: #ffa726;
  text-align: center;
  padding: 2%;
  transition: all 0.3s ease-in 0s;
  box-shadow: 0px 6px 16px 0px rgb(66 66 66 / 23%);
}

.BannerBillboardPanel1:hover {
  height: 280px;
  border-radius: 20px;
  background: white;
  text-align: center;
  padding: 2%;
  border-bottom: 10px solid #ffa726;
  box-shadow: 0px 6px 16px 0px rgb(66 66 66 / 23%);
}

.BannerBillboardPanel1:hover > h4,
.BannerBillboardPanel1:hover > p {
  color: #ffa726;
}

.BannerBillboardPanel2 {
  height: 280px;
  border-radius: 20px;
  background: #03a9f4;
  text-align: center;
  margin-left: 1%;
  padding: 2%;
  transition: all 0.3s ease-in 0s;
  box-shadow: 0px 6px 16px 0px rgb(66 66 66 / 23%);
}

.BannerBillboardPanel2:hover {
  height: 280px;
  border-radius: 20px;
  background: white;
  text-align: center;
  padding: 2%;
  border-bottom: 10px solid #03a9f4;
  box-shadow: 0px 6px 16px 0px rgb(66 66 66 / 23%);
}

.BannerBillboardPanel2:hover > h4,
.BannerBillboardPanel2:hover > p {
  color: #03a9f4;
}

.BannerBillboardPanel3 {
  height: 280px;
  border-radius: 20px;
  background: #8372cf;
  text-align: center;
  margin-left: 1%;
  padding: 2%;
  transition: all 0.3s ease-in 0s;
  box-shadow: 0px 6px 16px 0px rgb(66 66 66 / 23%);
}

.BannerBillboardPanel3 p {
  font-size: 16px;
}

.BannerBillboardPanel2 p {
  font-size: 16px;
}
.BannerBillboardPanel1 p {
  font-size: 16px;
}

.BannerBillboardPanel3:hover {
  height: 280px;
  border-radius: 20px;
  background: white;
  text-align: center;
  padding: 2%;
  border-bottom: 10px solid #8372cf;
  box-shadow: 0px 6px 16px 0px rgb(66 66 66 / 23%);
}

.BannerBillboardPanel3:hover > h4,
.BannerBillboardPanel3:hover > p {
  color: #8372cf;
}

.WelcomeBox {
  box-shadow: 0px 6px 16px 0px rgb(66 66 66 / 23%);
  border-radius: 50px;
  display: flex;
  overflow: hidden;
  height: 500px;
}

.WelcomeTextPanel {
  padding: 5%;
  width: 40%;
}

.WelcomeTextPanel {
  font-size: 20px;
}

.WelcomeTextPanel h3 {
  font-family: fredoka_bold;
  color: #ffa726;
  padding-bottom: 10px;
}

.WelcomeImg {
  width: 60%;
}

.WelcomeBtn {
  width: 150px;
  height: 50px;
  font-size: 1em;
}

.WelcomeBox::after {
  position: absolute;
  bottom: -10%;
  width: 740px;
  height: 70px;
  margin-left: -370px;
  background: url("../../assets/icons/home/ball-ball.png");
  content: "";
  left: 50%;
  background-repeat: no-repeat;
  z-index: -1;
}

.ExpectSection {
  background-image: url("../../assets/bg/expect.png");
  background-repeat: no-repeat;
  height: 637px;
  margin: 8em 0;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.ExpectSectionTitle {
  font-family: fredoka_bold;
  font-size: 35px;
  color: white;
  position: relative;
}

.ExpectSectionTitle::before {
  position: absolute;
  width: 500px;
  height: 35px;
  background-image: url("../../assets/icons/home/heading-icon2.png");
  background-repeat: no-repeat;
  background-position: center;
  content: "";
  top: -50%;
  margin-left: -180px;
}

.ExpectSectionTitle::after {
  position: absolute;
  width: 40px;
  height: 2px;
  left: 50%;
  margin-left: -20px;
  margin-top: 50px;
  background: #f8d311;
  content: "";
}

.ExpectSection p {
  color: white;
  padding: 20px 0;
}

.ExIconPanel {
  display: flex;
  align-items: center;
  width: 500px;
  padding: 0 20px;
}

.ExIcon {
  height: 90px;
  margin-left: 10px;
}

.ExIconTextPanel {
  margin-left: 20px;
}

.ExIconTextPanel h4 {
  color: white;
  font-family: fredoka_bold;
  padding-top: 10px;
}

.ExIconTextPanel p {
  color: white;
  padding: 0;
  font-size: 22px;
}

.EmptyBanner {
  height: 16em;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .SlideOneText {
    font-size: 50px;
  }
  .SlideStyle {
    height: 22em;
  }

  .BannerBillboard {
    bottom: 0;
    top: 90%;
  }

  .BannerBillboard p {
    font-size: 0.85em;
    font-weight: 600;
    padding: 0;
  }

  .BannerBillboardPanel1,
  .BannerBillboardPanel2,
  .BannerBillboardPanel3 {
    height: 280px;
    width: 60%;
    padding: 3%;
  }

  .EmptyBanner {
    height: 20em;
  }

  .WelcomeBox {
    height: 450px;
  }
}

@media screen and (min-width: 768px) and (max-width: 991px) {
  .SlideOneText {
    font-size: 35px;
    line-height: 40px;
    right: 45%;
    width: 40%;
  }

  .SlideStyle {
    height: 22em;
  }

  .BannerBillboard {
    bottom: 0;
    top: 90%;
  }

  .BannerBillboard p {
    font-size: 0.8em;
    font-weight: 600;
    padding: 0;
  }

  .BannerBillboardPanel1,
  .BannerBillboardPanel2,
  .BannerBillboardPanel3 {
    height: 280px;
    width: 60%;
    padding: 3%;
  }

  .EmptyBanner {
    height: 20em;
  }
  .WelcomeBox {
    height: 400px;
  }

  .WelcomeImg {
    width: 65%;
  }

  .WelcomeTextPanel {
    padding: 3%;
  }

  .WelcomeTextPanel p {
    font-size: 0.9em;
  }

  .WelcomeTextPanel h3 {
    padding-bottom: 5px;
  }

  .ExpectSection {
    margin: 5em 0;
    padding-top: 100px;
    height: 100%;
    text-align: center;
  }
}

@media screen and (min-width: 576px) and (max-width: 767px) {
  .SlideOneText {
    font-size: 30px;
    line-height: 40px;
    right: 45%;
    top: 15%;
    width: 40%;
  }
  .SlideStyle {
    height: 22em;
  }

  .BannerBillboard {
    bottom: 0;
    top: 95%;
  }

  .BannerBillboard h4 {
    letter-spacing: 0;
  }

  .BannerBillboard p {
    font-size: 1em;
    font-weight: 600;
    padding: 0;
  }

  .BannerBillboardPanel1,
  .BannerBillboardPanel2,
  .BannerBillboardPanel3 {
    height: 250px;
    width: 60%;
    padding: 3%;
  }

  .EmptyBanner {
    height: 50em;
  }

  .WelcomeBox {
    height: 300px;
  }

  .WelcomeImg {
    width: 60%;
  }

  .WelcomeTextPanel {
    padding: 3%;
  }

  .WelcomeTextPanel p {
    font-size: 0.75em;
  }

  .WelcomeTextPanel h3 {
    padding-bottom: 0;
  }

  .WelcomeBox::after {
    width: 576px;
    z-index: -1;
  }

  .ExpectSection {
    margin: 5em 0;
    height: 850px;
    text-align: center;
  }

  .ExpectSectionTitle {
    font-size: 30px;
  }

  .ExpectSectionTitle::before {
    height: 30px;
    margin-left: 0px;
    left: -140%;
  }

  .ExpectSection p {
    padding: 20px 20px;
  }

  .ExIconPanel {
    width: 360px;
  }

  .ExIcon {
    height: 70px;
  }

  .ExIconTextPanel {
    margin-left: 10px;
    text-align: start;
  }

  .ExIconTextPanel h4 {
    padding-top: 10px;
    font-size: 1.1em;
  }

  .ExIconTextPanel p {
    font-size: 0.8em;
    padding: 0;
  }
}

@media screen and (max-width: 575px) {
  .SlideOneText {
    font-size: 15px;
    line-height: 20px;
    right: 45%;
    top: 15%;
    width: 40%;
  }
  .SlideStyle {
    height: 13em;
  }

  .BannerBillboard {
    bottom: 0;
    top: 110%;
  }

  .BannerBillboard h4 {
    letter-spacing: 0;
  }

  .BannerBillboard p {
    font-size: 0.85em;
    font-weight: 600;
    padding: 0;
  }

  .BannerBillboardPanel1,
  .BannerBillboardPanel2,
  .BannerBillboardPanel3 {
    height: 250px;
    width: 60%;
    padding: 3%;
  }

  .EmptyBanner {
    height: 52em;
  }

  .WelcomeBox {
    height: 230px;
    border-radius: 20px;
  }

  .WelcomeImg {
    width: 60%;
  }

  .WelcomeTextPanel {
    padding: 3%;
    text-align: center;
    width: 50%;
  }

  .WelcomeTextPanel p {
    font-size: 0.5em;
    line-height: 12px;
    text-align: left;
    margin-bottom: 5px;
  }

  .WelcomeTextPanel h3 {
    padding-bottom: 0;
    font-size: 0.8em;
  }

  .WelcomeBtn {
    width: 60px;
    height: 30px;
  }

  .WelcomeBox::after {
    z-index: -1;
    bottom: -25%;
    width: 290px;
    height: 70px;
    margin-left: 0;
    left: 0;
  }

  .ExpectSection {
    margin: 5em 0;
    padding-top: 100px;
    height: 100%;
    text-align: center;
  }

  .ExpectSectionTitle {
    font-size: 30px;
  }

  .ExpectSectionTitle::before {
    height: 30px;
    margin-left: 0px;
    width: 150px;
    background-position: left;
  }

  .ExpectSection p {
    padding: 20px 0;
    width: 80%;
  }

  .ExIconPanel {
    width: 360px;
  }

  .ExIcon {
    height: 70px;
  }

  .ExIconTextPanel {
    margin-left: 10px;
    text-align: start;
  }

  .ExIconTextPanel h4 {
    padding-top: 10px;
    font-size: 1.1em;
  }

  .ExIconTextPanel p {
    font-size: 0.8em;
    padding: 0;
  }
}
