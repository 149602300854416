.Banner {
  background: #ffa726;
  width: 100%;
  height: 100px;
}

.Title {
  color: white;
  font-size: 2.5em;
  font-family: fredoka_bold;
  margin-top: 1%;
}

.MainLayout {
  margin-top: 3%;
  margin-bottom: 5%;
}

.MainLayout h4 {
  margin-bottom: 20px;
}

.CalenderLayout {
  margin-right: 25px;
}

.Calender {
  width: 100%;
  border: 1px solid #f0f0f0;
  border-radius: 5px;
}

.DetailPanel {
  border: 1px solid #f0f0f0;
  padding: 18px;
  border-radius: 5px;
  width: 95%;
}

@media screen and (max-width: 992px) {
  .BookTitle,
  .CalenderLayout,
  .DetailPanel {
    margin-left: 5%;
  }
  .BookTitle {
    margin-top: 30px;
  }
}
