.Banner {
  background: #03a9f4;
  width: 100%;
  height: 100px;
}

.Title {
  color: white;
  font-size: 2.5em;
  font-family: fredoka_bold;
  margin-top: 1%;
}
