.signup_form {
  display: block;
  margin: auto;
  width: 35%;
  padding-top: 10%;
  padding-bottom: 10%;
}
.Banner {
  background: #f44336;
  width: 100%;
  height: 100px;
}

.Title {
  color: white;
  font-size: 2.5em;
  font-family: fredoka_bold;
  margin-top: 1%;
}
