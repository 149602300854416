.Logo {
  color: white;
  text-align: left;
  font-size: 24px;
  margin: 28px 43px 66px 43px;
  overflow-wrap: break-word;
  font-weight: bolder;
  width: 160px;
  line-height: 29px;
  border-left: 5px solid #9fd9ff;
}
