.Banner {
  background: #f44336;
  width: 100%;
  height: 100px;
}

.Title {
  color: white;
  font-size: 2.5em;
  font-family: fredoka_bold;
  margin-top: 1%;
}

.InfoPanel {
  margin-top: 3%;
  margin-bottom: 5%;
  padding-right: 3%;
}

.InfoPanel h2 {
  font-family: fredoka_bold;
  color: #8bc34a;
  margin-bottom: 3%;
}

.InfoPanel p {
  font-size: 16px;
  padding: 10px 0;
}

.InfoImgPanel {
  margin-top: 3%;
}

.InfoImg {
  float: right;
  width: 100%;
}

.QRImg {
  width: 30%;
}

@media screen and (min-width: 576px) and (max-width: 991px) {
  .InfoImg {
    width: 100%;
  }
  .InfoPanel {
    margin-bottom: 0;
  }
}

@media screen and (max-width: 576px) {
  .InfoImg {
    width: 100%;
  }
  .InfoPanel {
    margin-bottom: 0;
  }

  .Title {
    font-size: 2em;
    margin-top: 10%;
  }
}
